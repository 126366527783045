﻿$fontSize: 16px;

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

$primaryColor: #171415;
$accentColor: #165ffb;
$backgroundColor: #ffffff;
$secondaryBackgroundColor: #f4f4f4;
$slashColor: #165ffb;
$slashOnDarkColorBackgroundColor: #165ffb;
$darkTextColor: #171415;
$lightTextColor: #f4f4f4;
$darkBackgroundColor: #171415;
$lightFont: #fff;
$fontFamily: 'DM Sans', sans-serif;
$darkFont: #171415;

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
