@import 'variables';

.test {
  font-size: $fontSize;
  font-family: $fontFamily;
  color: $primaryColor;
}

.testButton {
  position: fixed;
  top: 1em;
  right: 1em;
  padding: 1em;
  font-size: 16px;
  cursor: pointer;
  z-index: 1;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.eventsList {
  position: fixed;
  left: 1em;
  top: 1em;
  font-size: 14px;
}

.ssLogo {
  object-fit: contain;
  width: 10vw;
  max-width: 150px;
}
