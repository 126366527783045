@import-normalize;
@import 'variables';

body {
  margin: 0;
  color: $darkFont;
  font-size: $fontSize;
  background: $backgroundColor;
  font-family: $fontFamily;
  -webkit-tap-highlight-color: transparent;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  color: $darkFont;
}

button,
::placeholder {
  font-family: $fontFamily;
}

ul {
  list-style: none;
  padding: 0;
}

a {
  text-decoration: none;
  color: $darkFont;
}

button {
  color: currentColor;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
