.mapContainer {
  height: 100vh;
}

.resetMapButton {
  z-index: 4;
  position: fixed;
  bottom: 4em;
  left: 1em;
  background: black;
  color: white;
  border-radius: 4px;
  padding: 1em;
  border: none;
}

.logCameraPosition {
  z-index: 4;
  position: fixed;
  bottom: 8em;
  left: 1em;
  background: black;
  color: white;
  border-radius: 4px;
  padding: 1em;
  border: none;
}

.toggleFollowFlight {
  z-index: 4;
  position: fixed;
  bottom: 8em;
  left: 1em;
  background: black;
  color: white;
  border-radius: 4px;
  padding: 1em;
  border: none;
}

.fitToNorway {
  z-index: 4;
  position: fixed;
  bottom: 12em;
  left: 1em;
  background: black;
  color: white;
  border-radius: 4px;
  padding: 1em;
  border: none;
}

.resetPitch {
  z-index: 4;
  position: fixed;
  bottom: 16em;
  left: 1em;
  background: black;
  color: white;
  border-radius: 4px;
  padding: 1em;
  border: none;
}

.infoText {
  position: fixed;
  left: 1em;
  top: 40%;
  z-index: 5;
}
